import { getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import logoChattic from '~/assets/images/homepage/logo-chattic.svg?file'
import logoGGD from '~/assets/images/homepage/logo-ggd.svg?file'
import logoPmk from '~/assets/images/homepage/logo-pmk.svg?file'
import logoTradeaboat from '~/assets/images/homepage/logo-tradeaboat.svg?file'
import { fileToImageLikeData } from '~/utils'

import useHomepageStaticQuery from '../../views/Homepage/useHomepageStaticQuery'

export interface HomepageSuccesStoriesContent {
  image: IGatsbyImageData | undefined
  logo: string
  title: string
  link: string
  description: string | JSX.Element
  location: string
  category: { name: string }[]
}

const HomepageSuccesStories = (): HomepageSuccesStoriesContent[] => {
  const query = useHomepageStaticQuery()

  const successStories_1 = getImage(fileToImageLikeData(query.successStories_1))
  const successStories_2 = getImage(fileToImageLikeData(query.successStories_2))
  const successStories_3 = getImage(fileToImageLikeData(query.successStories_3))
  const successStories_4 = getImage(fileToImageLikeData(query.successStories_4))

  const SuccessStoriesContent = [
    {
      image: successStories_1,
      logo: logoGGD,
      title: 'Online domain name trading marketplace',
      link: 'marketplace-platform-for-trading-domain-names',
      description: (
        <>
          <span className="mr5">$2 mln traffic growth</span>for the past 2 years
          thanks to search engine optimization
        </>
      ),
      category: [
        { name: 'Domains' },
        { name: 'Marketplace' },
        { name: 'Website development' },
      ],
      location: 'USA',
    },
    {
      image: successStories_2,
      logo: logoPmk,
      title: 'Service marketplace',
      link: 'kids-activities-service-marketplace',
      description: (
        <>
          <span className="mr5">A top-notch service marketplace</span>that
          started with an MVP
        </>
      ),
      category: [
        { name: 'Parenting' },
        { name: 'Marketplace' },
        { name: 'Online booking' },
      ],
      location: 'USA',
    },
    {
      image: successStories_3,
      logo: logoChattic,
      title: 'Job matching service',
      link: 'online-marketplace-for-collectibles',
      description: (
        <>
          A<span className="mx5">ready-to-market</span>antiques marketplace
        </>
      ),
      category: [
        { name: 'Parenting' },
        { name: 'Marketplace' },
        { name: 'Online booking' },
      ],
      location: 'USA',
    },
    {
      image: successStories_4,
      logo: logoTradeaboat,
      title: 'Online marketplace',
      link: 'online-marketplace-for-boats',
      description: (
        <>
          A superior boat-selling<span className="mx5">online marketplace</span>
        </>
      ),
      category: [
        { name: 'Parenting' },
        { name: 'Marketplace' },
        { name: 'Online booking' },
      ],
      location: 'USA',
    },
  ]

  return SuccessStoriesContent
}

export default HomepageSuccesStories
