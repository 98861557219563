// extracted by mini-css-extract-plugin
export var homeWebDevProcess = "cF_tl";
export var homeWebDevProcess__between = "cF_tm";
export var homeWebDevProcess__contentFive = "cF_tw";
export var homeWebDevProcess__contentFour = "cF_tv";
export var homeWebDevProcess__contentOne = "cF_tr";
export var homeWebDevProcess__contentThree = "cF_tt";
export var homeWebDevProcess__contentTwo = "cF_ts";
export var homeWebDevProcess__description = "cF_tq";
export var homeWebDevProcess__image = "cF_tn";
export var homeWebDevProcess__title = "cF_tp";