import { Link } from 'gatsby'

import { ReactComponent as ArrowIconBlue } from '~/assets/images/icons/arrow-left-blue.svg'
import { useAddClassNameAfterMount } from '~/hooks/useAddClassNameAfterMount'
import * as styles3DIcons from '~/styles/modules/Sprite3DIcons.module.scss'
import { DataContentProps, WithOptional } from '~/types/helper'
import { HomeSpecificationLink } from '~/types/mock'
import { cx } from '~/utils'

import * as containerStyles from './SpecificationList.module.scss'

interface SpecificationListProps
  extends DataContentProps<WithOptional<HomeSpecificationLink, 'title'>> {}

const SpecificationList = ({ dataContent }: SpecificationListProps) => {
  const ref = useAddClassNameAfterMount<HTMLDivElement>({
    targetClassName: styles3DIcons.sprite3DIcons,
    loadedClassName: styles3DIcons.sprite3DIcons__sprite,
  })

  return (
    <div className="container container-md">
      <div
        ref={ref}
        className={cx(containerStyles.specificationList__wrapper, 'row')}
      >
        {dataContent.map(({ link, icon, title, maxWidth, description }) => (
          <div
            key={title}
            className={cx(
              'col-12 col-xl-4 col-md-6 col-lg-6',
              containerStyles.specificationList__itemWrapper,
            )}
          >
            <Link to={link} className={containerStyles.specificationList__item}>
              <div
                className={cx(
                  containerStyles.specificationList__icon,
                  styles3DIcons.sprite3DIcons,
                  styles3DIcons[`sprite3DIcons__${icon}`],
                )}
              />

              <h3
                className={containerStyles.specificationList__title}
                style={{ maxWidth: maxWidth }}
              >
                {title}
              </h3>

              <div className={containerStyles.specificationList__description}>
                {description}
              </div>

              <div className={containerStyles.specificationList__btn}>
                Learn more
                <ArrowIconBlue />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SpecificationList
