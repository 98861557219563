import { HomeSpecificationLink } from '~/types/mock'

const MainServicesList: HomeSpecificationLink[] = [
  {
    link: '/services/online-marketplace-development/',
    title: 'Online marketplace development',
    icon: 'sprite3DIcon_1',
    description:
      'Reliable, fast, and secure: all-in-one marketplace solutions for businesses of any size.',
  },
  {
    link: '/services/saas-development/',
    icon: 'sprite3DIcon_2',
    title: 'SaaS Development',
    maxWidth: '220px',
    description:
      'Performant cloud solutions for businesses looking to shift their operations online.',
  },
  {
    link: '/services/mvp-development/',
    icon: 'sprite3DIcon_3',
    title: 'MVP Development',
    maxWidth: '220px',
    description:
      'Quick and affordable product concept testing before investing in a full-scale product.',
  },
]

export default MainServicesList
