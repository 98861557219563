import { HomeSpecification } from '~/types/mock'

const HomeIndusctries: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    link: '/case-studies/kids-activities-service-marketplace/',
    title: 'Online Marketplace',
    description:
      'Marketplaces offer products and services from many sellers at once.',
    maxWidthDescription: '350px',
  },
  {
    icon: 'sprite3DIconSmall_13',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
    title: 'eCommerce',
    description: 'With SaaS, you can significantly raise your eCommerce sales.',
  },
  {
    icon: 'sprite3DIconSmall_14',
    link: '/case-studies/travel-management-platform/',
    title: 'Travel',
    description:
      'SaaS apps we developed help make trips simple, fast and pleasant.',
    maxWidthDescription: '350px',
  },
  {
    icon: 'sprite3DIconSmall_15',
    link: '/services/automotive-software-development/',
    title: 'Automotive',
    maxWidthTitle: '220px',
    description: 'We help automotive customers easily buy vehicles.',
  },
  {
    icon: 'sprite3DIconSmall_4',
    link: '/services/insurance-software-development/',
    title: 'Insurance',
    description:
      'We offer a number of advantages that can drive you revenue results.',
  },
  {
    icon: 'sprite3DIconSmall_11',
    link: '/case-studies/recruitment-progressive-web-application/',
    title: 'Recruiting',
    description:
      'With Codica, you receive a profitable recruiting SaaS solution.',
    maxWidthDescription: '350px',
  },
  {
    icon: 'sprite3DIconSmall_16',
    link: '/case-studies/accommodation-search-website-mvp/',
    title: 'Rental',
    description:
      'Rental apps we develop help you keep on top of your rental business.',
    maxWidthDescription: '350px',
  },
  {
    icon: 'sprite3DIconSmall_17',
    link: '/case-studies/news-aggregator/',
    title: 'Media',
    description:
      'We will provide a robust SaaS media app to skyrocket your business.',
    maxWidthDescription: '350px',
  },
  {
    icon: 'sprite3DIconSmall_18',
    link: '/case-studies/dental-imaging-management-system/',
    title: 'Healthcare',
    description:
      'We apply modern technologies for SaaS healthcare app development.',
  },
]

export default HomeIndusctries
