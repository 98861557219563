import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import * as containerButtonStyles from '~/components/buttons/Button/Button.module.scss'
import SubtractBlock from '~/components/shared/SubtractBlock'
import { cx } from '~/utils'

import * as containerStyles from './PrimeSection.module.scss'
interface PrimeSectionProps {
  title: string
  description: string
  buttonName?: string
  bgGradient?: string
  isSubtractBlock?: boolean
  isHomepageImage?: boolean
}

const PrimeSection = ({
  title,
  description,
  buttonName,
  bgGradient,
  isSubtractBlock,
  isHomepageImage,
}: PrimeSectionProps) => (
  <div
    className={cx(
      containerStyles.primeSection,
      containerStyles[`primeSection__${bgGradient}`],
    )}
  >
    <div className="container container-md">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className={containerStyles.primeSection__wrapper}>
            <h1 className={containerStyles.primeSection__head}>{title}</h1>
            <p className={containerStyles.primeSection__description}>
              {description}
            </p>
            {buttonName && (
              <Link
                to="/contacts/"
                className={cx(
                  containerButtonStyles.button,
                  containerButtonStyles.button__red,
                )}
              >
                {buttonName}
              </Link>
            )}
          </div>
        </div>
        {isHomepageImage && (
          <div className="d-none d-md-flex justify-content-end col-md-6">
            <div className={containerStyles.primeSection__imageWrapper}>
              <StaticImage
                src="../../../assets/images/homepage/prime.jpg"
                alt="Codica team members working on a new project | Codica"
                title="Codica team members working on a new project"
                loading="eager"
                quality={50}
              />
            </div>
          </div>
        )}
      </div>
    </div>
    {isSubtractBlock && <SubtractBlock loading="eager" />}
  </div>
)

export default PrimeSection
