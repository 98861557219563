import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import clutchRating from '~/assets/images/homepage/clutch-rating.svg?file'
import iconLocation from '~/assets/images/icons/icon-location.svg?file'
import { cx } from '~/utils'

import * as containerStyles from './SuccesStoriesCard.module.scss'

const SuccesStoriesCard = ({ dataContent }) => (
  <div className="container container-md">
    <div className={cx('row', containerStyles.succesStoriesCardRow)}>
      {dataContent.map(
        ({ image, logo, title, link, description, category, location }) => (
          <Link
            to={`/case-studies/${link}/`}
            key={title}
            className={cx('col-12 col-md-6', containerStyles.succesStoriesCard)}
          >
            <div className={containerStyles.succesStoriesCard__image}>
              <GatsbyImage
                image={image as IGatsbyImageData}
                alt={`${title} | Codica` || 'Image'}
                title={title}
                loading="lazy"
              />
            </div>

            <div className={containerStyles.succesStoriesCard__logosWrapper}>
              <div>
                <img
                  src={logo}
                  alt={`Logo: "${title}" | Codica`}
                  title={`Logo: "${title}"`}
                  width="100%"
                  height="100%"
                  loading="lazy"
                />
              </div>
              <div>
                <img
                  src={clutchRating}
                  alt={`Clutch Rating: "${title}" | Codica`}
                  title={`Clutch Rating: "${title}"`}
                  width="189px"
                  height="26px"
                  loading="lazy"
                />
              </div>
            </div>

            <div className={containerStyles.succesStoriesCard__title}>
              {title}
            </div>

            <div className={containerStyles.succesStoriesCard__description}>
              {description}
            </div>

            <div className={containerStyles.succesStoriesCard__bottomWrapper}>
              <div
                className={containerStyles.succesStoriesCard__categoryWrapper}
              >
                {category &&
                  category.map(({ name }) => (
                    <div
                      key={name}
                      className={containerStyles.succesStoriesCard__category}
                    >
                      {name}
                    </div>
                  ))}
              </div>

              <div className={containerStyles.succesStoriesCard__locaation}>
                <img
                  src={iconLocation}
                  alt={`Location: "${location}" | Codica`}
                  title={`Location: "${location}"`}
                  width="16px"
                  height="16px"
                  loading="lazy"
                />
                {location}
              </div>
            </div>
          </Link>
        ),
      )}
    </div>
  </div>
)

export default SuccesStoriesCard
