import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

import * as containerButtonStyles from '~/components/buttons/Button/Button.module.scss'
import { cx } from '~/utils'

import * as containerStyles from './CallToActionInfo.module.scss'

interface CallToActionInfoProps {
  title: string
  link: string
  linkName: string
  sectionBg?: string
  classNameForGA?: string
  image: IGatsbyImageData | JSX.Element | undefined
}

const CallToActionInfo = ({
  title,
  link,
  linkName,
  image,
  sectionBg,
  classNameForGA,
}: CallToActionInfoProps) => (
  <div className={containerStyles[`callToActionInfo__${sectionBg}`]}>
    <div className="container container-md">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6">
          <div className={containerStyles.callToActionInfo__wrapper}>
            <div className={containerStyles.callToActionInfo__title}>
              {title}
            </div>
            <Link
              to={link}
              className={cx(
                containerButtonStyles.button,
                containerButtonStyles.button__red,
                containerStyles.callToActionInfo__btn,
                classNameForGA,
              )}
            >
              {linkName}
            </Link>
          </div>
        </div>
        <div className="col-6 d-none d-lg-block">
          {React.isValidElement(image) ? (
            image
          ) : (
            <GatsbyImage
              image={image as IGatsbyImageData}
              alt={`${title} | Codica`}
              title={title}
              loading="lazy"
            />
          )}
        </div>
      </div>
    </div>
  </div>
)

export default CallToActionInfo
