import HeadSection from '~/components/blocks/HeadSection'
import SpecificationSmallList from '~/components/lists/SpecificationSmallList'
import OtherServicesList from '~/mock/homepage/OtherServicesList'

import * as containerStyles from './SpecificationOtherServicesSection.module.scss'

const SpecificationOtherServicesSection = () => (
  <div className={containerStyles.homeOtherServicesSection}>
    <HeadSection position="left" title="Other services we provide" />
    <SpecificationSmallList dataContent={OtherServicesList} />
  </div>
)

export default SpecificationOtherServicesSection
