import { lazy } from 'react'

import HeadSection from '~/components/blocks/HeadSection'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import { SvgComponent } from '~/types/common-props'
import { cx } from '~/utils'

import * as containerStyles from './HomeWebDevProcessSection.module.scss'

const CenterImage = lazy<SvgComponent>(() =>
  import('~/assets/images/homepage/web-dev-process.svg').then(
    ({ ReactComponent }) => ({ default: ReactComponent }),
  ),
)

const titles = [
  'Research & discovery',
  'Product design (UX and UI)',
  'Product development',
  'Optimization & QA',
  'Deployment, monitoring & support',
]

const descriptions = [
  'We refine your product idea, analyze the market, and plan the development.',
  'We visualize your idea, choose colors, fonts, and develop an identity for your future product.',
  'We use top-notch languages and frameworks to develop scalable and performant solutions.',
  'We employ testing techniques to ensure your product is as perfect as planned.',
  'We launch your product and analyze its performance, adding updates as needed.',
]

const HomeWebDevProcessSection = () => (
  <div className={containerStyles.homeWebDevProcess}>
    <HeadSection position="center" title="Our proven web development process" />

    <div className="container container-md">
      <div className="row">
        <div
          className={cx(
            'col-lg-12 col-xl-3',
            containerStyles.homeWebDevProcess__between,
          )}
        >
          <div
            className={cx(
              'd-none d-xl-block',
              containerStyles.homeWebDevProcess__contentFour,
            )}
          >
            <div className={containerStyles.homeWebDevProcess__title}>
              {titles[3]}
            </div>
            <div className={containerStyles.homeWebDevProcess__description}>
              {descriptions[3]}
            </div>
          </div>

          <div className={containerStyles.homeWebDevProcess__contentOne}>
            <div className={containerStyles.homeWebDevProcess__title}>
              {titles[0]}
            </div>
            <div className={containerStyles.homeWebDevProcess__description}>
              {descriptions[0]}
            </div>
          </div>

          <div className="d-xl-none">
            <div className={containerStyles.homeWebDevProcess__title}>
              {titles[1]}
            </div>
            <div className={containerStyles.homeWebDevProcess__description}>
              {descriptions[1]}
            </div>
          </div>
        </div>

        <div className="col-5 d-none d-xl-block">
          <RenderWhenVisible
            withSuspense
            fallback={<div style={{ width: 545, height: 439 }} />}
          >
            <CenterImage
              width={545}
              height={439}
              className={containerStyles.homeWebDevProcess__image}
            />
          </RenderWhenVisible>
        </div>

        <div
          className={cx(
            'col-lg-12 col-xl-4',
            containerStyles.homeWebDevProcess__between,
          )}
        >
          <div className={containerStyles.homeWebDevProcess__contentThree}>
            <div className={containerStyles.homeWebDevProcess__title}>
              {titles[2]}
            </div>
            <div className={containerStyles.homeWebDevProcess__description}>
              {descriptions[2]}
            </div>
          </div>

          <div
            className={cx(
              'd-none d-xl-block',
              containerStyles.homeWebDevProcess__contentTwo,
            )}
          >
            <div className={containerStyles.homeWebDevProcess__title}>
              {titles[1]}
            </div>
            <div className={containerStyles.homeWebDevProcess__description}>
              {descriptions[1]}
            </div>
          </div>

          <div className="d-xl-none">
            <div className={containerStyles.homeWebDevProcess__title}>
              {titles[3]}
            </div>
            <div className={containerStyles.homeWebDevProcess__description}>
              {descriptions[3]}
            </div>
          </div>

          <div
            className={cx(
              'd-none d-xl-block',
              containerStyles.homeWebDevProcess__contentFive,
            )}
          >
            <div className={containerStyles.homeWebDevProcess__title}>
              {titles[4]}
            </div>
            <div className={containerStyles.homeWebDevProcess__description}>
              {descriptions[4]}
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 d-xl-none">
          <div className={containerStyles.homeWebDevProcess__title}>
            {titles[4]}
          </div>
          <div
            className={containerStyles.homeWebDevProcess__description}
            style={{ marginBottom: 0 }}
          >
            {descriptions[4]}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default HomeWebDevProcessSection
