import HeadSection from '~/components/blocks/HeadSection'
import SpecificationIconSideTitleList from '~/components/lists/SpecificationIconSideTitleList'
import IntellectualProperty from '~/mock/homepage/IntellectualProperty'

import * as containerStyles from './HomeIntellectualPropertySection.module.scss'

const HomeIntellectualPropertySection = () => (
  <div className={containerStyles.homeIntellectualPropertySection}>
    <div className={containerStyles.homeIntellectualPropertySection__head}>
      <HeadSection
        position="left"
        title="How we ensure your intellectual property"
      />
    </div>

    <SpecificationIconSideTitleList dataContent={IntellectualProperty} />
  </div>
)

export default HomeIntellectualPropertySection
