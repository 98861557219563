import { HomeSpecification } from '~/types/mock'

const TrustedByBusinesses: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'Software development for startups',
    description:
      'At Codica, we specialize in cutting-edge software that suits the needs of any startup. Our team of experienced developers is dedicated to empowering entrepreneurs with innovative technology that accelerates growth, drives success, and is priced competitively.',
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'Small & medium businesses',
    description:
      'Codica offers scalable software solutions for small and medium-sized businesses to streamline operations, enhance productivity, and maximize efficiency. We are no strangers to automating processes, improving customer engagement, or optimizing workflows.',
  },
  {
    icon: 'sprite3DIconSmall_9',
    title: 'Custom enterprise software development',
    description:
      'With enterprise-level clients, we know how to deliver software that aligns seamlessly with the organization’s objectives and requirements. We leverage our expertise to develop robust, scalable, and secure software solutions that drive innovation and foster growth.',
    maxWidthDescription: '350px',
  },
]

export default TrustedByBusinesses
