import { lazy, useRef } from 'react'

import usePopup from '~/hooks/usePopup'
import usePopupScroll from '~/hooks/usePopupScroll'

const ConsultationFormModal = lazy(
  () => import('~/components/modals/ConsultationFormModal'),
)
const EbookModal = lazy(() => import('~/components/modals/EbookModal'))

const HomepageModalsSection = () => {
  const mountedEbookModal = useRef(false)
  const mountedConsultationModal = useRef(false)

  const {
    handleCloseIdlePopup: handleCloseIdlePopup,
    popupState: { showIdlePopup: showEbookPopup },
  } = usePopup('downloadEbook', 60 * 1000)

  const {
    handleClosePopup: handleCloseConsultationPopup,
    popupState: { showPopup: showConsultationPopup },
  } = usePopupScroll('consultationHome')

  const isShowEbookModal = showEbookPopup && !showConsultationPopup

  if (isShowEbookModal) mountedEbookModal.current = true
  if (showConsultationPopup) mountedConsultationModal.current = true

  return (
    <>
      {mountedEbookModal.current && (
        <EbookModal show={isShowEbookModal} onHide={handleCloseIdlePopup} />
      )}

      {mountedConsultationModal.current && (
        <ConsultationFormModal
          show={showConsultationPopup}
          onHide={handleCloseConsultationPopup}
        />
      )}
    </>
  )
}

export default HomepageModalsSection
