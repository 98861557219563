import { useEffect, useState } from 'react'

const usePopupScroll = (popupId) => {
  const [popupState, setPopupState] = useState({
    showPopup: false,
    dontShowPopupAgain: false,
  })

  useEffect(() => {
    const dontShowPopupAgainValue = sessionStorage.getItem(
      `dontShowPopupAgain_${popupId}`,
    )

    setPopupState((prev) => ({
      ...prev,
      dontShowPopupAgain: dontShowPopupAgainValue === 'true',
    }))
  }, [popupId])

  const handleScroll = () => {
    const scrollPosition = window.scrollY
    const documentHeight = document.documentElement.scrollHeight
    const windowHeight = window.innerHeight
    const scrollPercentage =
      (scrollPosition / (documentHeight - windowHeight)) * 100

    if (
      scrollPercentage >= 50 &&
      !popupState.showPopup &&
      !popupState.dontShowPopupAgain
    ) {
      setPopupState((prevState) => ({
        ...prevState,
        showPopup: true,
      }))
    }
  }

  useEffect(() => {
    const throttleScroll = () => {
      clearTimeout(throttleTimeout)
      throttleTimeout = setTimeout(handleScroll, 200)
    }

    let throttleTimeout

    window.addEventListener('scroll', throttleScroll)

    return () => {
      window.removeEventListener('scroll', throttleScroll)
      clearTimeout(throttleTimeout)
    }
  }, [popupState.showPopup, popupState.dontShowPopupAgain])

  const handleClosePopup = () => {
    setPopupState((prevState) => ({
      ...prevState,
      showPopup: false,
      dontShowPopupAgain: true,
    }))

    sessionStorage.setItem(`dontShowPopupAgain_${popupId}`, 'true')
  }

  return {
    popupState,
    handleClosePopup,
  }
}

export default usePopupScroll
