import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'

import { PhotosQuery } from '~/types/graphql'
import { fileToImageLikeData } from '~/utils'

const CeoImage = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      ceo: file(relativePath: { eq: "leadership/ceo.jpg" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  const ceo = getImage(fileToImageLikeData(query.ceo))

  return (
    <GatsbyImage
      image={ceo as IGatsbyImageData}
      alt="Dmytro, CEO of IT company Codica"
      title="Dmytro, CEO at Codica"
      loading="lazy"
    />
  )
}

export default CeoImage
