import { HomeSpecification } from '~/types/mock'

const OtherServicesList: HomeSpecification[] = [
  {
    link: '/services/custom-software-development/',
    icon: 'sprite3DIcon_4',
    title: 'Custom Software Development',
  },
  {
    link: '/services/mobile-app-development/',
    icon: 'sprite3DIcon_5',
    title: 'Mobile App Development',
    maxWidth: '220px',
  },
  {
    link: '/services/cloud-application-development/',
    icon: 'sprite3DIcon_6',
    title: 'Cloud app development',
    maxWidth: '220px',
  },
  {
    link: '/services/chatgpt-development-services/',
    icon: 'sprite3DIcon_7',
    title: 'ChatGPT development',
    maxWidth: '220px',
  },
  {
    link: '/services/ai-development-services/',
    icon: 'sprite3DIcon_8',
    title: 'AI development',
    maxWidth: '160px',
  },
  {
    link: '/services/product-discovery/',
    icon: 'sprite3DIcon_9',
    title: 'Product discovery',
    maxWidth: '185px',
  },
  {
    link: '/services/quality-assurance/',
    icon: 'sprite3DIcon_10',
    title: 'Quality Assurance Services',
    maxWidth: '220px',
  },
  {
    link: '/services/devops/',
    icon: 'sprite3DIcon_11',
    title: 'DevOps services',
    maxWidth: '130px',
  },
  {
    link: '/services/ui-ux-design/',
    icon: 'sprite3DIcon_12',
    title: 'UI/UX design',
    maxWidth: '130px',
  },
]

export default OtherServicesList
