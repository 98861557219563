import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { QueryResult, StrapiPage } from '~/types/graphql'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import Homepage from '~/views/Homepage/Homepage.view'

const IndexPage = () => <Homepage />

export const query = graphql`
  query {
    strapiPage(page_name: { eq: "Homepage" }) {
      ...MetaTagInformationPages
    }
  }
`

export default IndexPage

export const Head = ({ data }: QueryResult<StrapiPage>) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiPage,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image?.localFile)) as string}
      url="https://www.codica.com/"
      meta={[
        {
          name: 'msvalidate.01',
          content: 'DC327B5DDE02BFE4D2E4570A8FBB4A1B',
        },
      ]}
    />
  )
}
