import { Link } from 'gatsby'

import HeadSection from '~/components/blocks/HeadSection'
import * as containerButtonStyles from '~/components/buttons/Button/Button.module.scss'
import SuccesStoriesCard from '~/components/cards/SuccesStoriesCard'
import HomepageSuccesStories from '~/mock/homepage/HomepageSuccesStories'
import { cx } from '~/utils'

import * as containerStyles from './SuccesStoriesSection.module.scss'

const SuccesStoriesSection = () => {
  const HomepageSuccesStoriesContent = HomepageSuccesStories()

  return (
    <div className={containerStyles.succesStoriesSection}>
      <HeadSection position="left" title="Success stories" />

      <SuccesStoriesCard dataContent={HomepageSuccesStoriesContent} />

      <div className="container container-md">
        <div className="row">
          <div className="col-12">
            <div className={containerStyles.succesStoriesSection__cta}>
              <div className={containerStyles.succesStoriesSection__ctaTitle}>
                Do you have a business in another field?
              </div>
              <Link
                to="/contacts/"
                className={cx(
                  containerButtonStyles.button,
                  containerButtonStyles.button__red,
                )}
              >
                Schedule the call
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccesStoriesSection
