import { Link } from 'gatsby'

import { ReactComponent as ArrowIconBlue } from '~/assets/images/icons/arrow-left-blue-large.svg'
import { useAddClassNameAfterMount } from '~/hooks/useAddClassNameAfterMount'
import * as styles3DIcons from '~/styles/modules/Sprite3DIcons.module.scss'
import { cx } from '~/utils'

import * as containerStyles from './SpecificationSmallList.module.scss'

const SpecificationSmallList = ({ dataContent }) => {
  const ref = useAddClassNameAfterMount<HTMLDivElement>({
    targetClassName: styles3DIcons.sprite3DIcons,
    loadedClassName: styles3DIcons.sprite3DIcons__sprite,
  })

  return (
    <div className="container container-md">
      <div
        ref={ref}
        className={cx(containerStyles.specificationSmallList__wrapper, 'row')}
      >
        {dataContent.map(({ link, icon, title, maxWidth }) => (
          <div
            key={title}
            className={cx(
              'col-12 col-xl-4 col-md-6',
              containerStyles.specificationSmallList__itemWrapper,
            )}
          >
            <Link
              to={link}
              className={containerStyles.specificationSmallList__item}
            >
              <div
                className={cx(
                  containerStyles.specificationSmallList__icon,
                  styles3DIcons.sprite3DIcons,
                  styles3DIcons[`sprite3DIcons__${icon}`],
                )}
              />
              <div
                className={containerStyles.specificationSmallList__title}
                style={{ maxWidth: maxWidth }}
              >
                {title}
              </div>
              <div className={containerStyles.specificationSmallList__btn}>
                <ArrowIconBlue />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SpecificationSmallList
