import HeadSection from '~/components/blocks/HeadSection'
import Leadership from '~/components/sections/Leadership'
import LazyHydrate from '~/components/shared/LazyHydrate'

import * as containerStyles from './LeadershipSection.module.scss'

const LeadershipSection = () => (
  <div className={containerStyles.homeLeadershipSection}>
    <HeadSection position="left" title="Leadership team" />
    <LazyHydrate whenVisible>
      <Leadership />
    </LazyHydrate>
  </div>
)

export default LeadershipSection
