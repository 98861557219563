import CompanyInfo from '~/components/blocks/CompanyInfo'

import * as containerStyles from './HomeCompanyInfo.module.scss'

const HomeCompanyInfo = () => (
  <div className={containerStyles.homeComanyInfo}>
    <CompanyInfo
      title="We are a team of software development experts"
      description="Codica has been driven by passion and ambition. These qualities formed our philosophy and keep motivating us to craft outstanding software products. Through years of effort, we’ve amassed expertise to provide comprehensive product development services, knowing every stage of the lifecycle like the palm of our hand."
    />
  </div>
)

export default HomeCompanyInfo
