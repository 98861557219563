import { DataContentProps, WithOptional } from '~/types/helper'
import { HomeSpecification } from '~/types/mock'
import { cx } from '~/utils'

import * as containerStyles from './SpecificationIconSideTitleList.module.scss'

interface SpecificationIconSideTitleListProps
  extends DataContentProps<WithOptional<HomeSpecification, 'title'>> {}

const SpecificationIconSideTitleList = ({
  dataContent,
}: SpecificationIconSideTitleListProps) => (
  <div className="container container-md">
    <div className="row">
      {dataContent.map(({ title, description }) => (
        <div
          key={title}
          className={cx(
            'col-12 col-md-6',
            containerStyles.specificationIconSideTitleList__col,
          )}
        >
          <h3 className={containerStyles.specificationIconSideTitleList__head}>
            {title}
          </h3>
          <p className={containerStyles.specificationIconSideTitleList__text}>
            {description}
          </p>
        </div>
      ))}
    </div>
  </div>
)

export default SpecificationIconSideTitleList
