// extracted by mini-css-extract-plugin
export var achievementsSlider__badge_1 = "fn_KZ";
export var achievementsSlider__badge_10 = "fn_K8";
export var achievementsSlider__badge_11 = "fn_K9";
export var achievementsSlider__badge_12 = "fn_Lb";
export var achievementsSlider__badge_13 = "fn_Lc";
export var achievementsSlider__badge_14 = "fn_Ld";
export var achievementsSlider__badge_15 = "fn_Lf";
export var achievementsSlider__badge_16 = "fn_Lg";
export var achievementsSlider__badge_17 = "fn_Lh";
export var achievementsSlider__badge_18 = "fn_Lj";
export var achievementsSlider__badge_19 = "fn_Lk";
export var achievementsSlider__badge_2 = "fn_K0";
export var achievementsSlider__badge_3 = "fn_K1";
export var achievementsSlider__badge_4 = "fn_K2";
export var achievementsSlider__badge_5 = "fn_K3";
export var achievementsSlider__badge_6 = "fn_K4";
export var achievementsSlider__badge_7 = "fn_K5";
export var achievementsSlider__badge_8 = "fn_K6";
export var achievementsSlider__badge_9 = "fn_K7";
export var achievementsSlider__logo = "fn_KY";
export var achievementsSlider__swiperSliderWrapper = "fn_KX";