import QuoteLarge from '~/components/items/QuoteLarge'

import CeoImage from './CeoImage'
import * as containerStyles from './QuoteCeoLargeSection.module.scss'

const QuoteCeoLargeSection = () => (
  <div className={containerStyles.homeQuoteCeoSection}>
    <QuoteLarge
      image={<CeoImage />}
      title="“Applying our domain and technical expertise, we advise on the most efficient ways to make your product successful and profitable."
      description="I’m proud to say that Codica is a team with product-oriented thinking. Having expert developers on board, we deliver great code quality, but this is not our only goal. From the start of our collaboration, we focus on the value we deliver to each project.”"
      name="Dmytro Chekalin"
      position="CEO at Codica"
    />
  </div>
)

export default QuoteCeoLargeSection
