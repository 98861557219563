import { lazy } from 'react'

import HeadSection from '~/components/blocks/HeadSection'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import TrustedByBusinesses from '~/mock/homepage/TrustedByBusinesses'
import { SvgComponent } from '~/types/common-props'
import { cx } from '~/utils'

import * as containerStyles from './TrustedBusinessesSection.module.scss'

const ClutchImage = lazy<SvgComponent>(() =>
  import('~/assets/images/homepage/clutch-top-custom-dev-bg-blue.svg').then(
    ({ ReactComponent }) => ({ default: ReactComponent }),
  ),
)

const TrustedBusinessesSection = () => (
  <div className={containerStyles.homeTrustedBusinessesSection}>
    <div
      className={cx(
        'container container-md',
        containerStyles.homeTrustedBusinessesSection__wrapperLogo,
      )}
    >
      <div className={containerStyles.homeTrustedBusinessesSection__logo}>
        <RenderWhenVisible
          withSuspense
          fallback={<div style={{ width: 213, height: 100 }} />}
        >
          <ClutchImage width={213} height={100} />
        </RenderWhenVisible>
      </div>
    </div>
    <div className={containerStyles.homeTrustedBusinessesSection__head}>
      <HeadSection position="left" title="Trusted by businesses of any size" />
    </div>
    <ServicesIndustriesCard dataContent={TrustedByBusinesses} />
  </div>
)

export default TrustedBusinessesSection
