import { lazy } from 'react'

import HeadSection from '~/components/blocks/HeadSection'
import EmptyFallback from '~/components/render-kit/EmptyFallback'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import PostsSliderMock from '~/mock/PostsSlider'

import * as containerStyles from './HomePostSliderSection.module.scss'

const PostsSlider = lazy(() => import('~/components/sliders/PostsSlider'))

const HomePostSliderSection = () => (
  <div className={containerStyles.homePostsArtcilesSection}>
    <div className={containerStyles.homePostsArtcilesSection__head}>
      <HeadSection position="left" title="Featured articles" />
    </div>

    <div className="container container-md">
      <div className="row">
        <RenderWhenVisible
          withSuspense
          fallback={<EmptyFallback height={452} />}
          className={containerStyles.homePostsArtcilesSection__render}
        >
          <PostsSlider dataContent={PostsSliderMock} />
        </RenderWhenVisible>
      </div>
    </div>
  </div>
)

export default HomePostSliderSection
