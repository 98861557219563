import { lazy } from 'react'

import HeadSection from '~/components/blocks/HeadSection'
import EmptyFallback from '~/components/render-kit/EmptyFallback'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import ClientVideoQuoteSliderMock from '~/mock/ClientVideoQuoteSlider'

import * as containerStyles from './HomeClientQuotesSection.module.scss'

const ClientVideoQuoteSlider = lazy(
  () => import('~/components/sliders/ClientVideoQuotesSlider'),
)

const HomeClientQuotesSection = () => (
  <div className={containerStyles.homepageClientQuotesSection}>
    <div className={containerStyles.homepageClientQuotesSection__head}>
      <HeadSection position="left" title="What our clients say" />
    </div>
    <RenderWhenVisible
      withSuspense
      fallback={<EmptyFallback width="100%" height={516} />}
    >
      <ClientVideoQuoteSlider
        activeSlideIndex={0}
        dataContent={ClientVideoQuoteSliderMock}
      />
    </RenderWhenVisible>
  </div>
)

export default HomeClientQuotesSection
