import HeadSection from '~/components/blocks/HeadSection'
import SpecificationList from '~/components/lists/SpecificationList'
import MainServicesList from '~/mock/homepage/MainServicesList'

import * as containerStyles from './SpecificationMainServicesSection.module.scss'

const SpecificationMainServicesSection = () => (
  <div className={containerStyles.homeMainServicesSection}>
    <HeadSection position="left" title="Our main services" />
    <SpecificationList dataContent={MainServicesList} />
  </div>
)

export default SpecificationMainServicesSection
