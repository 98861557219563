import CallToActionInfo from '~/components/call-to-actions/CallToActionInfo'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSection from '~/components/sections/PrimeSection'
import LazyHydrate from '~/components/shared/LazyHydrate'

import AchievementsSliderSection from './components/AchievementsSliderSection'
import CallToActionImageFirst from './components/CallToActionImages/CallToActionImageFirst'
import CallToActionImageSecond from './components/CallToActionImages/CallToActionImageSecond'
import CompanyProjectsLogoSection from './components/CompanyProjectsLogoSection'
import HomeClientQuotesSection from './components/HomeClientQuotesSection'
import HomeCompanyInfo from './components/HomeCompanyInfo'
import HomeIntellectualPropertySection from './components/HomeIntellectualPropertySection'
import HomepageModalsSection from './components/HomepageModalsSection/HomepageModalsSection.view'
import HomePostSliderSection from './components/HomePostSliderSection'
import HomeWebDevProcessSection from './components/HomeWebDevProcessSection'
import IndustriesSection from './components/IndustriesSection'
import LeadershipSection from './components/LeadershipSection'
import OurCoreValuesSection from './components/OurCoreValuesSection'
import QuoteCeoLargeSection from './components/QuoteCeoLargeSection'
import SpecificationMainServicesSection from './components/SpecificationMainServicesSection'
import SpecificationOtherServicesSection from './components/SpecificationOtherServicesSection'
import SuccesStoriesSection from './components/SuccesStoriesSection'
import TrustedBusinessesSection from './components/TrustedBusinessesSection'

const Homepage = () => (
  <MainLayout scrollTop>
    <PrimeSection
      isHomepageImage
      title="Your Trusted Partner in Custom Software Excellence"
      description="Building technology that transforms your ideas to top-notch product."
      buttonName="Estimate my project"
    />

    <AchievementsSliderSection />

    <CompanyProjectsLogoSection />

    <SpecificationMainServicesSection />

    <SpecificationOtherServicesSection />

    <HomeWebDevProcessSection />

    <CallToActionInfo
      title="We create web products that users love"
      link="/contacts/"
      linkName="Get in touch"
      image={<CallToActionImageFirst />}
      sectionBg="homeGradientFirst"
      classNameForGA="homepageCTA_1"
    />

    <QuoteCeoLargeSection />

    <OurCoreValuesSection />

    <CallToActionInfo
      title="From idea validation to fully functional bespoke web products"
      link="/contacts/"
      linkName="Get in touch"
      image={<CallToActionImageSecond />}
      sectionBg="homeGradientSecond"
      classNameForGA="homepageCTA_2"
    />

    <IndustriesSection />

    <SuccesStoriesSection />

    <HomeIntellectualPropertySection />

    <HomeClientQuotesSection />

    <TrustedBusinessesSection />

    <HomeCompanyInfo />

    <LeadershipSection />

    <HomePostSliderSection />

    <PrimeContactFormSection title="Let’s turn your idea into a successful product!" />

    <LazyHydrate whenIdle>
      <HomepageModalsSection />
    </LazyHydrate>
  </MainLayout>
)

export default Homepage
