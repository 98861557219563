import { HomeSpecification } from '~/types/mock'

const IntellectualProperty: HomeSpecification[] = [
  {
    title: 'Privacy protection with NDA',
    description:
      'A strict non-disclosure policy within Codica eliminates the chance of leaks and associated issues. We regularly update our policy when onboarding new team members and teach seasoned professionals.',
  },
  {
    title: 'Business ethics and reputation',
    description:
      'We implement appropriate business policies and practices to ensure that neither you nor your reputation is compromised. We care about public image and do our best to bring this vision to any partnership.',
  },
  {
    title: 'Transparent agreements',
    description:
      'On the legal side, we work with complete transparency. We never neither withhold information, nor complicate it. Plain and simple.',
  },
  {
    title: 'Design and code belong to clients',
    description:
      'We build end-to-end software, leaving you to play all the cards. The design, code, and all associated materials are at your disposal.',
  },
]

export default IntellectualProperty
