import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'

import { PhotosQuery } from '~/types/graphql'
import { fileToImageLikeData } from '~/utils'

const CallToActionImageFirst = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      ctaImageFirst: file(relativePath: { eq: "homepage/cta-dan-case.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  const ctaImageFirst = getImage(fileToImageLikeData(query.ctaImageFirst))

  const alt = 'We create web products'

  return (
    <GatsbyImage
      image={ctaImageFirst as IGatsbyImageData}
      alt={`${alt} | Codica`}
      title={alt}
      aria-hidden="false"
      loading="lazy"
    />
  )
}

export default CallToActionImageFirst
