import { useEffect, useRef } from 'react'
import { FreeMode, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import LazyHydrate from '~/components/shared/LazyHydrate'
import { cx } from '~/utils'

import * as containerStyles from './AchievementsSlider.module.scss'
import './AchievementsSlider.scss'

const achievements = [
  { icon: 'badge_1' },
  { icon: 'badge_2' },
  { icon: 'badge_3' },
  { icon: 'badge_4' },
  { icon: 'badge_5' },
  { icon: 'badge_6' },
  { icon: 'badge_7' },
  { icon: 'badge_8' },
  { icon: 'badge_9' },
  { icon: 'badge_10' },
  { icon: 'badge_11' },
  { icon: 'badge_12' },
  { icon: 'badge_13' },
  { icon: 'badge_14' },
  { icon: 'badge_15' },
  { icon: 'badge_16' },
  { icon: 'badge_17' },
  { icon: 'badge_18' },
  { icon: 'badge_19' },
]

const AchievementsSlider = () => {
  const swiperRef = useRef<any>(null)
  const containerRef = useRef<any>(null)

  useEffect(() => {
    if (containerRef.current) {
      const observer = new IntersectionObserver(([entry]) => {
        if (swiperRef.current) {
          const { swiper } = swiperRef.current
          if (entry.isIntersecting) {
            swiper.autoplay.start()
          } else {
            swiper.autoplay.stop()
          }
        }
      })

      observer.observe(containerRef.current)

      return () => {
        observer.disconnect()
      }
    }
  }, [])

  const settings = {
    ref: swiperRef,
    speed: 8000,
    slidesPerView: 1,
    loop: true,
    freeMode: true,
    allowTouchMove: true,
    modules: [FreeMode, Autoplay],
    className: 'achievementsSlider',
    autoplay: { delay: 250, disableOnInteraction: false },
    breakpoints: {
      1100: {
        slidesPerView: 6,
        autoplay: { delay: 500, disableOnInteraction: false },
      },
      900: {
        slidesPerView: 5,
      },
      700: {
        slidesPerView: 4,
      },
      350: {
        slidesPerView: 2,
      },
    },
  }

  return (
    <div
      ref={containerRef}
      className={containerStyles.achievementsSlider__swiperSliderWrapper}
    >
      <LazyHydrate whenIdle>
        <Swiper {...settings}>
          {achievements.map(({ icon }) => (
            <SwiperSlide key={icon}>
              <div
                className={cx(
                  containerStyles.achievementsSlider__logo,
                  containerStyles[`achievementsSlider__${icon}`],
                )}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </LazyHydrate>
    </div>
  )
}

export default AchievementsSlider
