import HeadSection from '~/components/blocks/HeadSection'
import AchievementsSlider from '~/components/sliders/AchievementsSlider'

import * as containerStyles from './AchievementsSliderSection.module.scss'

const AchievementsSliderSection = () => (
  <div className={containerStyles.homeAchievementsSection}>
    <div className={containerStyles.homeAchievementsSection__headSection}>
      <HeadSection
        position="left"
        title="Our achievements"
        description="Leading rating & review platforms rank among top software development companies due to our tech assistance in clients’ digital transformation."
      />
    </div>

    <AchievementsSlider />
  </div>
)

export default AchievementsSliderSection
