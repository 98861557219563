import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'

import { PhotosQuery } from '~/types/graphql'
import { fileToImageLikeData } from '~/utils'

const CallToActionImageSecond = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      ctaImageSecond: file(
        relativePath: { eq: "homepage/cta-caker-case.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  const ctaImageSecond = getImage(fileToImageLikeData(query.ctaImageSecond))

  return (
    <GatsbyImage
      image={ctaImageSecond as IGatsbyImageData}
      alt="From idea validation to fully functional bespoke web products | Codica"
      title="From idea validation to fully functional bespoke web products"
      loading="lazy"
    />
  )
}

export default CallToActionImageSecond
