import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

import * as containerStyles from './QuoteLarge.module.scss'

interface QuoteLargeProps {
  title: string
  description: string
  name: string
  position: string
  image: IGatsbyImageData | JSX.Element | undefined
}

const QuoteLarge = ({
  image,
  title,
  description,
  name,
  position,
}: QuoteLargeProps) => (
  <div className="container container-md">
    <div className="row">
      <div className="col-12 col-lg-5">
        {React.isValidElement(image) ? (
          image
        ) : (
          <GatsbyImage
            image={image as IGatsbyImageData}
            alt={`${name}, ${position}`}
            title={`${name}, ${position}`}
            loading="lazy"
            className={containerStyles.quoteLarge__image}
          />
        )}
      </div>
      <div className="col-12 col-lg-7">
        <div className={containerStyles.quoteLarge__wrapperInfo}>
          <div className={containerStyles.quoteLarge__title}>{title}</div>
          <div className={containerStyles.quoteLarge__description}>
            {description}
          </div>
          <div className={containerStyles.quoteLarge__wrapper}>
            <div className={containerStyles.quoteLarge__name}>{name}</div>
            <div className={containerStyles.quoteLarge__position}>
              {position}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default QuoteLarge
