import HeadSection from '~/components/blocks/HeadSection'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import HomeIndusctries from '~/mock/homepage/HomeIndusctries'

import * as containerStyles from './IndustriesSection.module.scss'

const IndustriesSection = () => (
  <div className={containerStyles.homeIndustriesSection}>
    <div className={containerStyles.homeIndustriesSection__head}>
      <HeadSection
        position="left"
        title="Software development services for various industries"
      />
    </div>

    <ServicesIndustriesCard dataContent={HomeIndusctries} />
  </div>
)

export default IndustriesSection
