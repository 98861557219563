import { useEffect, useState } from 'react'

import { useIdle } from '~/hooks'

const DEFAULT_IDLE_TIMEOUT = 30 * 1000

const usePopup = (popupId, idleTimeout = DEFAULT_IDLE_TIMEOUT) => {
  const idle = useIdle(idleTimeout)

  const [popupState, setPopupState] = useState({
    showIdlePopup: false,
    dontShowIdlePopupAgain: false,
  })

  const { dontShowIdlePopupAgain, showIdlePopup } = popupState

  useEffect(() => {
    const dontShowIdlePopupAgainValue = sessionStorage.getItem(
      `dontShowIdlePopupAgain_${popupId}`,
    )

    setPopupState((prev) => ({
      ...prev,
      dontShowIdlePopupAgain: dontShowIdlePopupAgainValue === 'true',
    }))
  }, [popupId])

  useEffect(() => {
    if (idle && !showIdlePopup && !dontShowIdlePopupAgain) {
      setPopupState((prev) => ({
        ...prev,
        showIdlePopup: true,
      }))
    }
  }, [idle, showIdlePopup, dontShowIdlePopupAgain])

  const handleCloseIdlePopup = () => {
    setPopupState({
      showIdlePopup: false,
      dontShowIdlePopupAgain: true,
    })

    sessionStorage.setItem(
      `dontShowIdlePopupAgain_${popupId}`,
      showIdlePopup.toString(),
    )
  }

  return {
    popupState,
    handleCloseIdlePopup,
  }
}

export default usePopup
